
import { Options, mixins } from "vue-class-component";
import Layout from "@/components/Layout.vue";

import Verification from "@/components/Verification/index.vue";
import TwoFactorValidated from "@/mixins/TwoFactorValidated";
import Download from "@/components/Download/index.vue";

import { fileSaver } from "@/plugins/fileSaver";
import { axiosInstanceToken } from "@/plugins/axios";
import { Endpoint } from "@/api/endpoints";
import { File } from "@/models/File";

@Options({
  components: {
    Layout,
    Verification,
    Download,
  },
})
export default class ClientDownload extends mixins(TwoFactorValidated) {
  loadingFile = false;
  loadingFiles = false;

  verifications = {
    files: "clientFiles",
    getFiles: (id: string): string => Endpoint.CLIENT_FILES(id).medsurveyUrl,
    sendCode: (id: string): string =>
      Endpoint.CLIENT_SEND_CODE(id).medsurveyUrl,
    generateCode: (id: string): string =>
      Endpoint.CLIENT_GENERATE_SEND_CODE(id).medsurveyUrl,
    applyCode: (id: string): string =>
      Endpoint.CLIENT_APPLY_CODE(id).medsurveyUrl,
  };

  async saveFile(fileId: string): Promise<any> {
    return await axiosInstanceToken.get(
      Endpoint.CLIENT_FILE_DOWNLOAD(this.id, fileId).medsurveyUrl,
      {
        responseType: "blob",
      }
    );
  }

  async onFileDownload(file: File): Promise<void> {
    this.loadingFile = true;
    try {
      const { data } = await this.saveFile(file.fileId);

      await fileSaver(data, `${file.fileName}.${file.fileExtension}`);
    } catch (error: any) {
      // this.showError("notValid");
    }

    this.loadingFile = false;
  }

  async onDownloadAll(): Promise<void> {
    this.loadingFiles = true;

    try {
      const { data } = await axiosInstanceToken.get(
        Endpoint.CLIENT_FILE_DOWNLOAD_ALL(this.id).medsurveyUrl,
        {
          responseType: "blob",
        }
      );

      await fileSaver(data, `${this.projectName}.zip`);
    } catch (error: any) {
      // this.showError("notValid");
    }

    this.loadingFiles = false;
  }
}
