import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Download = _resolveComponent("Download")!
  const _component_Verification = _resolveComponent("Verification")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, {
    footer: false,
    layout: _ctx.loading && _ctx.isVerification ? 'sm-center' : 'sm'
  }, {
    content: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Download, {
            key: 0,
            projectName: _ctx.projectName,
            files: _ctx.rows,
            loadingFiles: _ctx.loadingFiles,
            loadingFile: _ctx.loadingFile,
            onOnDownloadAllAction: _ctx.onDownloadAll,
            onOnDownloadItemAction: _ctx.onFileDownload
          }, null, 8, ["projectName", "files", "loadingFiles", "loadingFile", "onOnDownloadAllAction", "onOnDownloadItemAction"]))
        : _createCommentVNode("", true),
      (_ctx.loading && _ctx.isVerification)
        ? (_openBlock(), _createBlock(_component_Verification, {
            key: 1,
            id: _ctx.id,
            verifications: _ctx.verifications,
            onSuccess: _ctx.getFiles
          }, null, 8, ["id", "verifications", "onSuccess"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["layout"]))
}