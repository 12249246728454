import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-color" }
const _hoisted_2 = { class: "container container_full" }
const _hoisted_3 = { class: "container__header" }
const _hoisted_4 = { class: "space-between" }
const _hoisted_5 = { class: "sub-title" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled", "onClick"]
const _hoisted_8 = { class: "space-between" }
const _hoisted_9 = { class: "meta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Title, { size: "full" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.projectName), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("downloadFiles")), 1),
          _createElementVNode("button", {
            disabled: _ctx.loadingFiles,
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.downloadAll && _ctx.downloadAll(...args)), ["prevent"])),
            class: "secondary-btn",
            style: {"width":"166px"}
          }, _toDisplayString(_ctx.$t(`${_ctx.loadingFiles ? "loading" : "downloadAll"}`)), 9, _hoisted_6)
        ])
      ]),
      _createVNode(_component_Table, {
        columns: _ctx.columns,
        rows: _ctx.files,
        class: "custom-table_height"
      }, {
        row_fileName: _withCtx(({ option: { row: file } }) => [
          _createTextVNode(_toDisplayString(`${file.fileName}.${file.fileExtension}`), 1)
        ]),
        row_fileSize: _withCtx(({ option: { row: file } }) => [
          _createTextVNode(_toDisplayString(_ctx.formatBytesSize(Number(file.fileSize))), 1)
        ]),
        buttons_action: _withCtx(({ option: { row: file } }) => [
          _createElementVNode("button", {
            disabled: _ctx.loadingFiles || _ctx.loadingFile,
            onClick: _withModifiers(($event: any) => (_ctx.download(file)), ["prevent"]),
            class: "secondary-btn",
            style: {"width":"166px"}
          }, _toDisplayString(_ctx.$t(`${_ctx.loadingFile ? "loading" : "download"}`)), 9, _hoisted_7)
        ]),
        _: 1
      }, 8, ["columns", "rows"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("secureFile")), 1)
    ])
  ], 64))
}