
import { Options, Vue } from "vue-class-component";
import { File } from "@/models/File";

import Title from "@/components/Title.vue";
import Table from "@/components/Table/index.vue";

import { formatBytes } from "@/utils";

@Options({
  props: {
    projectName: {
      type: String,
      required: true,
    },
    loadingFiles: {
      type: Boolean,
      default: false,
    },
    loadingFile: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Title,
    Table,
  },
  inheritAttrs: false,
})
export default class Download extends Vue {
  columns = [
    {
      label: "Name",
      key: "fileName",
      customWidth: "4fr",
    },
    {
      label: "Size",
      key: "fileSize",
    },
    {
      label: "",
      key: "buttons_action",
      customWidth: "166px",
    },
  ];

  downloadAll(): void {
    this.$emit("onDownloadAllAction");
  }

  download(file: File): void {
    this.$emit("onDownloadItemAction", file);
  }

  formatBytesSize(val: number): string {
    return formatBytes(val);
  }
}
